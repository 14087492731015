@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.expiring-leases-list {
  .expiring-lease {
    margin-bottom: 1px;

    .label {
      color: $primary-01;
    }
  }
}

.expiring-lease {
  margin-bottom: 1rem;

  .blxs-button-primary {
    position: absolute;
    right: 0;
    bottom: 40px;
  }

  .labeled-item {
    .autocomplete-container {
      max-width: 180px;
    }

    .property-item {
      white-space: pre;
    }

    .flex-row {
      .blxs-icon-button {
        margin-left: 20px;
        font-size: 14px;
      }
    }
  }

  .lease-info {
    & > div {
      min-width: 20%;
      max-width: 20%;

      @media (max-width: 768px) {
        min-width: 33.333%;
        max-width: 33.333%;
      }
    }
  }
}

.rep-role-pill {
  font-size: .8rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  text-transform: uppercase;
  color: $white;

  &.lease-tenant {
    background-color: $information;
  }

  &.lease-landlord {
    background-color: $focus;
  }
}

.total-boxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .total-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    height: 95px;
    width: 100%;
    margin-right: 10px;
    padding: 10px;
    border-radius: 2px;

    &:last-child {
      margin-right: 0;
    }

    .bold {
      font-weight: 500;
    }

    .total {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-size: 24px;
      line-height: 1;
    }

    .total-count {
      color: var(--dark-02);
    }
  }
}
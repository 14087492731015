@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.tag {
  border-radius: 100px;
  border: solid 2px $dark-01;
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;

  .label-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 5px;

    p {
      font-weight: bold;
      font-size: 12px;
      margin: 0 auto;
      padding-bottom: 0;
      line-height: 1rem;
    }
  
    .small-label {
      font-size: 11px;
      font-weight: normal;
      padding-bottom: 0;
      margin: 0 auto;
    }
  }

  svg:hover {
    cursor: pointer;
  }
}
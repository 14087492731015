@import "../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass";

.search-input {
  position: relative;

  input {
    padding-left: 36px;
  }

  .icon-magnifier {
    z-index: 1;
    position: absolute;
    left: 10px;
    top: 11px;
    font-size: 18px;
  }

  .blxs-icon-button {
    z-index: 1;
    position: absolute;
    font-size: 18px;
    right: 10px;
    top: 11px;
  }
}

.deal-info-header {
  padding: 20px;
  line-height: 1.2;
  margin-right: 30px;

  .deal-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.deal-info-content {
  flex: 1;
  padding: 20px;

  section:not(:last-child) {
    margin-bottom: 30px;
    > * {
      margin-bottom: 10px;
    }
  }

  .section-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

}

.tabs-card {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

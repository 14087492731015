@import '../node_modules/@cbrebuild/blocks-base/dist/variables.sass';
@import './blocks-theming.scss';

$header-height: 76px;
$footer-height: 50px;

:root {
  --dark-00: rgb(43, 56, 54);
  --dark-01: rgb(82, 96, 97);
  --dark-02: rgb(118, 132, 133);
  --dark-03: rgb(137, 178, 181);
  --light-00: #f1f3f7;
  --light-01: #dfe3eb;
  --light-02: #cad0d8;
  --primary-00: #01523d;
  --primary-01: #027a41;
  --primary-02: #93cd54;
  // --primary-03: unassigned
  // --secondary-00: #405d68;
  // --secondary-01: #608997;
  // --secondary-02: #80b4c7;
  // --secondary-03: unassigned;
  --information: #6f6bf8;
  --danger-00: #db5030;
  --danger-01: #eb5534;
  // --danger-02: unassigned
  // --danger-03: unassigned

  // Using original colors
  --white: #ffffff;
  --focus: #49a1ff;
  --warning: #fdb100;
  --success: #39cf94;
  --brand-00: #a2ffbb;
  --brand-01: #00ff44;

  --base-font-color: var(--dark-01);
}

body {
  background-color: $light-02;
}

input {
  font-size: 14px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

span[class^="icon"] {
  cursor: pointer;
}

// Global Blocks Overrides
.blx-primary, .blx-danger, .blx-dark {
  color: $light-00;
}

.blx-secondary {
  background-color: $light-00;
}
//TODO: Since h tags are not links, gradually update the h styles to use $dark-00
h1, h2, h3, h4, h6 {
  color: var(--primary-00);
}

h5 {
  color: $dark-00
}

.blx-dropdown {
  min-width: unset;
}

.blx-dropdown-trigger {
  background-color: $light-01;

  &:hover, &.blx-active {
    background-color: $white;
  }
}

a:-webkit-any-link {
  text-decoration: none;
}

.blx-toggle .blx-toggle-container input:checked~.blx-toggle-text {
  background: $primary-01;
}

.blx-toggle .blx-toggle-container .blx-toggle-text {
  &:hover {
    background: $primary-02;
  }
}

.blx-horizontal-tabs {
  input[type=radio]:focus + .blx-tab-item {
    outline: none;
    color: $primary-00;

    &:after {
      background: $primary-00;
    }
  }
}

button.blx-button {
  &.blx-secondary {
    background: $light-00;
    border-color: $light-00;

    &:hover {
      background: $secondary-01;
      border-color: $secondary-01;
    }
  }
}

// Make Popovers responsive (ie: larger)
@media (max-width: 450px) {
  .blx-popover-menu {
    max-width: 500px;
    width: 90vw;
  }
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
}

.app-header {
  position: fixed;
  width: 100%;
  padding: 18px 20px;
  background-color: $dark-01;
  z-index: 2;
  height: $header-height;

  &, & > *, & * {
    color: $light-00;
  }
}

.header-controls {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }

  .icon-message {
    font-size: 24px;
    margin-right: 1.75rem;
  }
}

.content {
  padding-top: $header-height;
  display: flex;
  margin: auto;
  min-height: calc(100vh - #{$footer-height});


  &.showing-hijack-banner {
    padding-top: 7rem;
  }
}

.page {
  width: 100%;
  height: 100%;
  padding: 20px;

  h2 {
    margin-bottom: 20px;
    margin-left: -2px;
  }
}

table.perf-table {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;

  th {
    padding: 1rem;
    border-radius: 2px;
    background-color: $light-01;
    // color: $light-00;
  }
  tr {
    padding: 1rem;
    border-radius: 4px;
    background-color: $light-00;

    &:hover {
      background-color: $white;
    }
  }

  td {
    padding: 1rem;
  }
}

.in-development {
  margin-top: 3rem;
  padding: 1rem;
  border-radius: 2px;
  background-color: $light-00;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 3.5rem;
  &, & > *, & * {
    color: $light-00;
  }
  background-color: $dark-01;
  text-align: center;

  a:hover {
    color: $primary-02;
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.hijack-banner {
  margin: 0.5rem -1rem -1rem -1rem;
  padding: 0.5rem 2rem;
  background: $dark-00;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .blxs-button-secondary span {
    color: #007841;
  }
}

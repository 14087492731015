@import '../../Shared.scss';
@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.edit-columns-button {
  @include reset-button();
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 7px;

  p {
    margin-right: 12px;
  }

  &:hover,
  &:focus {
    background-color: $white;
    padding: 0 15px;
    border: 1px solid $light-02;
  }

  &:focus {
    border-color: $focus;
  }

  .icon-menu {
    transform: rotate(90deg);
  }
}

.blx-sortable-list .blx-sortable-list-item {
  padding: 2px 0;
  align-items: center;
}

.blx-icon.blx-icon-drag {
  height: 14px;
}

input.blxs-checkbox-input:focus {
    outline:0;
}

.blxs-checkbox-label {
  margin-left: 0.75rem;
  font-weight: 500;
}

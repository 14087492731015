.pipeline {
  h1 {
    margin-bottom: 20px;
  }

  .pipeline-data {
    width: 80vw;
  }

  .filter-container {
    min-width: 574px;
  }

  .invalid-icon {
    margin-right: 10px;
  }
}

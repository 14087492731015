@import '../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row, .flex-col {
  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.mb1 {
  margin-bottom: 1rem;
}

.labeled-item {
  padding-right: 2rem;
  padding-bottom: 2rem;
  text-transform: capitalize;

  .label {
    color: $dark-01;
    margin-bottom: 4px;
  }

  .subtext {
    text-transform: initial;
  }
}

.card {
  padding: 1rem;
  border-radius: 4px;
  background-color: $light-00;

  h1, h2, h3, h4, h5, h6 {
    color: $primary-01;
  }
}

.scroll-box {
  background: $white;
  padding: 1rem;
  max-height: 560px;
  overflow-y: scroll;
  margin-bottom: 2rem;
  box-shadow: 0 1px 2px 0 var(--shadow,rgba(170,183,178,.6));
}

.two-column > * {
  min-width: 50%;
}

.subtext {
  font-size: 11.5px;
  color: $dark-01;

  &.error {
    color: $danger-01;
  }
}

.text-button {
  font-weight: 500;
  // text-decoration: underline;
  cursor: pointer;
  color: var(--primary-00);
}

.relative {
  position: relative;
}

.invisible {
  visibility: hidden;
}

@mixin reset-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

// hide without removing from tab order (for keyboard navigation)
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin rest-visually-hidden {
  border: 0;
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible; // for calcendar dropdown
  padding: 0;
  position: relative;
  white-space: normal;
  width: auto;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin multiline-ellipsis($linelimit) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $linelimit;
  overflow: hidden;
}
$content-width: 900px;

.quick-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  display: block;

  &.hide {
    animation: hide .5s ease-out forwards;

    .quick-view-overlay {
      animation: fadeout .3s ease-out forwards;
    }
    .quick-view-container {
      animation: flyout .3s ease-out forwards;
    }
  }
}

.quick-view-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  animation: fadein .3s ease forwards;
}

$continer-padding: 20px;

.quick-view-container {
  position: absolute;
  top: 0;
  width: $content-width;
  height: 100%;
  background-color: rgb(255,255,255);
  animation: flyin .3s ease forwards;
  display: flex;
  flex-direction: column;

  .close-icon {
    position: absolute;
    right: $continer-padding;
    top: $continer-padding;
  }
}

@keyframes flyin {
  from {
    right: -1 * $content-width;
  }
  to {
    right: 0;
  }
}

@keyframes flyout {
  from {
    right: 0;
  }
  to {
    right: -1 * $content-width;
    overflow: hidden;
  }
}

@keyframes fadein {
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}

@keyframes hide {
  to {
    height: 0;
  }
}
@import "../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass";

.notification-preferences {
  p {
    margin-bottom: 2rem;
  }

  span {
    margin-right: 1rem;
  }

  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $light-02;
}

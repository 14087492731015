@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.control-bar {
  display: flex;
  width: 100%;
  border-radius: 2px;
  background-color: $light-01;
  padding: 10px;
  flex-wrap: wrap;

  .control-buttons {
    flex-wrap: wrap;
  }

  .blx-dropdown-menu, .flyout-content {
    z-index: 2; //sticky table header has z-index: 1;
  }
}
  
@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.autocomplete-container {
  position: relative;
  max-width: 280px;

  input {
    padding-left: 30px;
    padding-right: 24px;
    height: 36px;
    width: 100%;
    border-radius: 7px;
    border: solid 2px $dark-00;
  }

  .blxs-icon-button {
    position: absolute;
    right: 10px;
    top: 11px;
    font-size: 14px;
  }

  .icon-magnifier {
    font-size: 16px;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .suggestions {
    position: absolute;
    z-index: 1;
    top: 38px;
    left: 0;
    right: 0;
    background: $white;
    box-shadow: 0 1px 2px 0 var(--shadow,rgba(170,183,178,.6));
    max-height: 360px;
    overflow-y: auto;

    .suggestion {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: $dark-03;
      }
    }
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.topnav {
  .icon-menu {
    position: relative;
    top: 4px;
    left: -6px;
    font-size: 18px;
    padding: 6px;
    border-radius: 7px;
    cursor: pointer;

    &:hover {
      background: $white;
      color: $dark-01;
    }
  }

  .avatar {
    cursor: pointer;
  }

  .topnav-flyout {
    background: $white;
    font-size: 18px;
    border-radius: 7px;
  }

  a {
    white-space: nowrap;
    color: $dark-00;
    padding: 12px;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-of-type {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background: $primary-02;
    }
  }
}

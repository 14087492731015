@import '../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';


.terms-of-service {
  .card {
    padding: 1rem;
  }

  h5 {
    margin: 1rem 0;
  }

  p {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .blxs-checkbox-label {
    margin: auto 2rem auto 1rem;
    font-size: 20px;
  }
}

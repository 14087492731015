.privacy-page {
  h4 {
    margin: 1.25rem 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p, ul {
    margin-bottom: 1rem;
  }

  li, a, p {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  li {
    margin: 0 0 0.75rem 2rem;

    &::before {
      content: "•";
      margin-right: 1rem;
    }
  }
}

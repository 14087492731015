.multi-select-item {
  display: flex;
  align-items: center;

  &.hidden {
    display: none;
  }

  // group toggle button
  .group-toggle {
    width: unset; // neutralize blocks button width
    min-width: 14px;
    margin-left: 16px;
    padding: inherit;
    text-overflow: initial;
    > * {
      font-size: 14px; // override blocks v2 icon button size of 1.25rem
    }

    &.blxs-icon-button-basic:focus-within {
      outline: none;
      box-shadow: unset;

      [class*=" icon-"] {
        font-weight: bold;
      }
    }
  }

  // options-item as button
  button.option-item {
    display: flex;
    align-items: center;

    // checkbox
    label.blxs-checkbox {
      margin-bottom: 0; // blocks v2 override
      margin-right: 10px;
    }

    // text area
    .option-text {
      overflow: hidden;
      text-overflow: ellipsis;
      .subtext {
        display: block;
        line-height: 1;
      }
    }

    &.select-all {
      padding-left: 46px; // indentation
    }
  }

  &.nested-option button.option-item {
    padding-left: 74px; // indentation
    height: unset; // space for subtext
  }
}



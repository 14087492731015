.flyout {
  position: relative;

  .flyout-content {
    position: absolute;
    top: 100%;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6));
    z-index: 1;
  }

  .blx-dropdown-trigger {
    .icon-chevron-down {
      font-size: 22px;
      color: var(--primary-00);
      transition: transform 0.2s;
    }
    &.blx-active {
      .icon-chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.managing-office-access {
  .office-list {
    div {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  .request-access-modal {
    display: block;
    overflow: auto;

    p, h4 {
      margin-bottom: 10px;
    }

    b {
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }

  .tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0 80px 0;
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      text-decoration: underline;
      font-size: 16px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .blx-modal {
    min-height: 280px;
  }

  .blx-v-button-group {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    right: 20px;

    .blxs-button {
      margin: 20px 10px 20px 10px;
    }
  }
}

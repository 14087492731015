@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.action-history {
  table {
    width: 100%;
  }

  th {
    color: $dark-01;
  }

  th, td {
    padding: 10px;
  }

  td {
    background: $white;
  }

  tr {
    &:not(:first-of-type) {
      &:not(:last-of-type) {
        border-bottom: 1px solid $light-01;
      }
    }
  }

  .has-margin-left {
    margin-left: 20px
  }
  
  .blx-text-field {
    width: 100%;
  }

  .blxs-textarea {
    font-size: 14px;
    line-height:18px;
  }

  .note-body {
    margin: 8px 0px;
    border: 1px solid $light-01;
    border-radius: 7px;
    padding: 16px 10px;
    font-size: 14px;
    white-space: normal; // not working, TODO: get line breaks to render correctly
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.avatar {
  height: 40px;

  img, .initials {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }

  .initials {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 18px;
    background: $primary-02;
    color: $white;
    border-color: $primary-02;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    padding: 0 4px;

    &:hover {
      color: $dark-01;
      background-color: $primary-02;
      border-color: $primary-02;
    }
  }
}
@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';
@import '../../Shared.scss';

.table-section {
  position: relative;
}

.table-scroll {
  overflow: auto;

  .table {    
    .td, .th {
      padding: 0 16px;
      line-height: 48px;

      &.text-align-right {
        text-align: right;
      }

      &.cell-ellipsis {
        @include ellipsis();
      }
    }

    .th {
      color: $light-00;
      font-weight: 500;
      text-transform: uppercase;
      font-variant: small-caps;
      font-size: 11px;
      letter-spacing: 1.65px;
      white-space: pre-wrap;
      line-height: 1.4;
      background-color: $dark-01;
      position: sticky;
      top: 0;
      z-index: 1;
      vertical-align: top;
      padding: 10px 16px;

      .header-wrapper {
        display: flex;

        .fixed-line-header {
          word-wrap: break-word;
          @include multiline-ellipsis(2);
        }

        .sort-disabled {
          color: $light-01;
          cursor: wait;
        }

        .sorting-indicator {
          font-size: 18px;
          margin-left: 10px;
          display: inline-block;
          width: 20px;
          padding-top: 5px;
          span {
            vertical-align: top;
          }
        }

        .select-header-icon {
          font-size: 18px;
        }
      }
    }

    .tr {
      background-color: $secondary-00;

      &.even {
        background-color: $light-00;
      }
    }
  }
}

label.blxs-checkbox {
  display: inline;
}


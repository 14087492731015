@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.datepicker-container {
  position: relative;
  display: inline-block;
  width: 100%;

  .icon-calendar {
    pointer-events: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 20px;
  }

  // override styles of 3rd party date picker
  .DayPickerInput {
    width: 100%;

    input {
      height: 40px;
      width: 100%;
      padding: 12px;
      border: 1px solid $light-01;
      border-radius: 7px;

      &:focus {
        outline: none;
        border-color: $focus;
      }
    }
  }

  .DayPicker {
    .DayPicker-Day {
      &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $primary-01;
      }

      &.DayPicker-Day:not(.DayPicker-Day--outside):hover {
        background-color: rgba(0,166,87, 0.1) !important;
        color: $primary-01;
      }

      &.DayPicker-Day--today {
        color: $primary-01;

        &.DayPicker-Day--selected {
          color: $white;
        }
      }
    }
  }
}

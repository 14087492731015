@import '../../Shared.scss';

.past-due:hover .past-due-tooltip {
  display: block;
}

.past-due-tooltip {
  display: none;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  background: #3C4242;
  line-height: 25px;
  border-radius: 10px;
  color: white;
  font-weight: 300;
}

.inline-edit-container {
  display: flex;
  align-items: center;
}

.inline-edit {
  // min-heght as input height to display text vertically in the center
  min-height: 40px; 
  align-items: center;
  width: 100%;

  &:hover, &:focus-within {
    border: 0;

    .inline-edit-input {
      @include rest-visually-hidden;
    }

    .inline-edit-value {
      @include visually-hidden;
    }
  }

  .inline-edit-input {
    @include visually-hidden;
    .datepicker-container {
      line-height: 1.5rem;
    }
  }

  .blx-text-field {
    // neutralize blocks textfields width
    min-width: 50px;
    width: 100%;
  }

  .inline-edit-value {
    padding: 0 16px; // adjust horizontal position to match with input's left/right paddings
    width: 100%;
    text-align: left;
    position: relative;
  }
}
@import "../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass";

$filter-cta-height: 80px;
$filter-paddings: 15px 20px;
$element-space: 10px;
$filter-max-height: 360px;

.filters {
  min-width: 300px;
  max-height: $filter-max-height;
  overflow-y: auto;
  background-color: white;
  border-radius: inherit;

  .filter-content {
    padding: $filter-paddings;
    overflow-x: hidden;

    .filter-row {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      .filter-label {
        color: $dark-01;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 20px;
      }

      .blx-text-field,
      .datepicker-container {
        width: calc(50% - 8px);

        label {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 0;
        }

        input {
          width: 100%;
          background: $white;
          &::placeholder {
            text-transform: uppercase;
            color: $dark-02; // same as Dropdown's placeholder text
          }
        }
      }

      .min-max-dash {
        width: 16px;
        height: 100%;
        margin: 20px 5px 0 5px;
      }

      .blx-dropdown {
        max-width: unset;

        .blx-dropdown-trigger {
          background-color: initial;
        }

        .blx-dropdown-menu {
          max-height: 242px;
          overflow-y: auto;
        }
      }

      .blx-toggle {
        white-space: nowrap;
      }
    }
  }

  .filter-cta {
    padding: $filter-paddings;
    display: flex;
    justify-content: space-between;
    align-items: center;

    *:not(:last-child) {
      margin-right: $element-space;
    }
  }
}

.single-filter {
  .blx-dropdown-menu {
    right: 0;
    width: auto;
    max-width: 460px;
    border-radius: 7px;
    max-height: $filter-max-height;
    overflow: hidden;
    
    &:not(.blx-hidden) {
      display: flex;
      flex-direction: column;

      .blx-dropdown-list {
        max-height: 100%;
        overflow: scroll;
      }
    }
  }

  .blx-dropdown-trigger .blx-dropdown-placeholder {
    color: var(--dark-00);
  }
}

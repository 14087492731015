@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.switch-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  .switch {
    position: relative;
    display: inline-block;
    height: 17px;
    width: 30px;
    margin-right: 10px;
  }

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    background-color: $light-02;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .2s;
    border-radius: 34px;

    &::before {
      position: absolute;
      background-color: $white;
      content: "";
      height: 13px;
      width: 13px;
      bottom: 2px;
      left: 2px;
      transition: .2s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $primary-02;
  }
  input:disabled + .slider {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }

  label {
    font-size: 16px;
  }
}

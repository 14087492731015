@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.breadcrumbs {
  margin-bottom: 1rem;

  & > * {
    text-decoration: none;
    text-transform: uppercase;
    color: $dark-02;
    font-size: 11px;
    line-height: 1.64;
    letter-spacing: 1.65px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipses;

    &:before {
      content: ">";
      padding-left: 8px;
      padding-right: 8px;
      color: $dark-02;
    }

    &:first-child:before {
      content: "";
      padding: 0;
    }

    &.active {
      color: var(--primary-00);
      pointer-events: none;
    }
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

$filter-tag-vertical-spacing: 0.625rem;
$filter-tag-height: 24px;

.filter-tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: $white;
  padding: $filter-tag-vertical-spacing 0.75rem 0;
  border-radius: 2px;

  .filter-tag {
    display: flex;
    align-items: center;
    background: $dark-01;
    color: $white;
    padding: 0.5rem 0.75rem;
    margin: 0 0.5rem $filter-tag-vertical-spacing 0;
    height: $filter-tag-height;
    border-radius: $filter-tag-height / 2;
    text-transform: capitalize;
    font-weight: 300;

    .blxs-icon-button {
      margin-left: 0.5rem;
      color: $light-02;
      .blxs-icon{
        font-size: 1rem;
      }

      &:hover {
        color: $white;
      }
    }
  }

  .clear-all-button {
    color: $primary-01;
    margin-bottom: $filter-tag-vertical-spacing;
    cursor: pointer;

    &:hover {
      color: $primary-00;
    }
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.alert {
  margin: 0.5rem -1rem -1rem -1rem;
  padding: 0.5rem 2rem;
  background: $dark-00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 2px;
}
.permission-denied-page {
  max-width: 525px;
  margin: 0 auto;

  h2 {
    font-size: 34px;
    margin: 150px 0 0 0;
  }

  p {
    margin-top: 10px;
  }

  .button-container {
    margin-top: 33px;
    display: flex;

    button {
      margin: 0 10px 0 0;
    }
  }
}

@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.lease-notes {
  margin-top: 10px;

  h4 {
    margin-bottom: 10px;
  }

  button.blxs-button {
    height: 32px;
    padding: 4px 16px;
    margin-top: 10px;
  }

  .blx-text-field {
    width: 100%;
  }

  .blxs-textarea {
    font-size: 14px;
    line-height:18px;
  }
}

@import '../../Shared.scss';
@import '../../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.download-file {
  @include reset-button();
  width: 124px; // set-width so content change during loading state doesn't shrink button
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 7px;

  &:hover,
  &:focus {
    background-color: $white;
    padding: 0 15px;
    border: 1px solid $light-02;
  }

  &:focus {
    border-color: $focus;
  }

  &:disabled {
    pointer-events: none;
    font-style: italic;
  }

  p {
    margin-right: 12px;
  }

  .icon-download::before {
    color: $primary-00;
  }
}

.modal-content {
  margin-bottom: 1rem
}

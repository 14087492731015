@import '../../node_modules/@cbrebuild/blocks-base/dist/variables.sass';

.profile-page {
  .flex-row {
    .avatar {
      margin-right: 1rem;
    }

    .user-info {
      h4 {
        color: $dark-00;
        margin-bottom: .5rem;
      }

      div {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.5px;
      }
    }
  }

  .blx-horizontal-tabs {
    margin-bottom: 20px;
  }

  .profile-card {
    padding: 20px 30px 50px 30px;

    h3 {
      color: #4d4d4d;
      letter-spacing: 0.33px;
      margin-bottom: 1.5rem;
    }

    p {
      max-width: 610px;
    }
  }

  .links {
    padding: 10px;
    > * {
      font-weight: 500;
      margin-right: 20px;
    }
  }
}

.field-row {
  overflow: hidden;
  padding-top: 13;
}

.field-row label {
  display: block;
  width: 15%;
  float: left;
  padding-top: 12px;

}

.field-row .field-container {
  width: 85%;
  float: left;
  min-width:150px;
  max-width:170px;
}
